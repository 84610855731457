@font-face {
    font-family: "Assistant";
    src: url("assets/fonts/Assistant-VariableFont_wght.ttf");
    font-weight: 200 800;
}

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
    box-sizing: border-box;
}

/*
	2. Remove default margin
  */
* {
    margin: 0;
}

/*
	3. Allow percentage-based heights in the application
  */
html,
body {
    height: 100%;
}

/*
	Typographic tweaks!
	4. Add accessible line-height
	5. Improve text rendering
  */
body {
    font-family: "Assistant", sans-serif;
    overflow: hidden;
    direction: rtl;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

/*
	6. Improve media defaults
  */
img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

/*
	7. Remove built-in form typography styles
  */
input,
button,
textarea,
select {
    font: inherit;
}

/*
	8. Avoid text overflows
  */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

a {
    all: unset;
    cursor: pointer;
}

/*
	9. Create a root stacking context
  */
#root,
#__next {
    isolation: isolate;
}

section {
    height: 100vh;
    width: 100vw;
}

label {
    font-weight: 300;
}

.logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.logo-container .text {
    max-width: 60%;
    text-align: center;
    line-height: 1.2;
    color: var(--c-neutral-100);
}

a.active {
    color: var(--c-active-nav-item) !important;
    background: var(--gradient-bg);
}

a.active path,
a.active circle,
a.active rect {
    fill: var(--c-active-nav-item);
}

.user {
    display: flex;
    align-items: center;
    gap: 0.8em;
    position: relative;
}

.logout {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 1em;
    bottom: -100%;
    background-color: var(--c-neutral-100);
    padding: 0.2em 1em;
    border-radius: 0.4em;
    left: 0;
    box-shadow: var(--box-shadow-150);
}

.logout svg {
    height: 1em;
}

.open-actions {
    transform: translateX(0) !important;
}

.search-bar {
    position: relative;
    width: 24%;
}

.search-bar svg {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
}

.App-payment .rccs {
    direction: ltr;
}

.form-container {
    padding: 2em 3.5em;
}

.action-btns {
    display: flex;
    flex-wrap: wrap;
    gap: 2vmin;
    max-width: 90%;
    justify-content: center;
    margin-top: 24px;
}

.action-btns button {
    width: 40%;
    min-width: 0;
}

tr {
    transition: background-color 0.25s ease;
    position: relative;
}

tr.active td {
    cursor: pointer;
    background-color: var(--c-accent-300);
}

tr:hover td,
tr.hovered td {
    cursor: pointer;
    background-color: var(--c-neutral-300);
}

tr.in-array td {
    background-color: var(--c-accent-300);
}

td.not-active:hover,
td.not-active {
    background-color: transparent !important;
    pointer-events: none;
}

td.not-active button {
    display: inline-flex;
    pointer-events: auto;
}

td svg {
    min-width: 25px;
}

.disable-user {
    pointer-events: none;
}

.disable-user .warning-modal {
    pointer-events: all;
}

.warning-modal.top {
    z-index: 11 !important;
}

.com-section {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 0.5em;
    text-align: center;
    left: 0;
    background: var(--c-accent-200-a);
    font-size: 1.4em;
    border-radius: 0 0 24px 24px;
    font-weight: 700;
    color: var(--c-main-700);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.2vmin;
}

.date-input {
    display: flex;
    gap: 1em;
    width: 80%;
    justify-content: space-between;
}

.date-input input {
    border-radius: var(--border-radius);
    background: transparent;
    border: 1px solid var(--c-neutral-600);
    max-width: 8em;
    font-weight: 300;
    font-size: var(--fz-regular);
    padding-inline: 0.5em;
}

.comment {
    cursor: pointer;
    color: var(--c-main-600);
}

.comment:hover {
    color: var(--c-main-700);
}

.styled-input input {
    border-radius: var(--border-radius);
    border: 1px solid var(--c-main-100);
    background-color: var(--c-main-100);
    padding: 0.3vmin 1vmin;
}

.styled-input input:focus-within {
    outline: none;
    border: 1px solid var(--c-main-500);
}

.styled-input .react-datepicker__triangle::after {
    border-bottom-color: var(--c-main-600) !important;
}

/* react date picker styles */

.react-datepicker {
    font-family: inherit !important;
    border: unset !important;
    background-color: var(--c-main-600) !important;
    border-radius: var(--border-radius) !important;
    padding: 0.3em;
    font-size: 1rem !important;
    box-shadow: var(--box-shadow-500);
}

.react-datepicker__month {
    border-radius: var(--border-radius) !important;
}

.react-datepicker__triangle::after {
    border-bottom-color: var(--c-main-600) !important;
}

.react-datepicker__header {
    background-color: var(--c-main-600) !important;
    border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
    border-bottom: unset !important;
}

.react-datepicker__current-month {
    font-size: var(--fz-accent) !important;
    padding: 0.5em;
    margin-bottom: 1em;
    border-bottom: 1px solid var(--c-neutral-100);
    text-align: right !important;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__current-month {
    color: var(--c-neutral-100) !important;
    font-weight: 500 !important;
}

.react-datepicker__day-name {
    font-size: var(--fz-accent) !important;
    font-weight: 700 !important;
}

.react-datepicker__day--disabled {
    color: var(--c-main-700) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day:hover {
    background-color: var(--c-neutral-100) !important;
    color: var(--c-main-600) !important;
    border-radius: 100% !important;
}

.react-datepicker button span::before {
    content: unset;
}

.react-datepicker button {
    background-color: var(--c-neutral-100);
    background-image: url("./assets/images/arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30%;
    border-radius: 100%;
    top: 8%;
}

.react-datepicker__navigation--previous {
    transform: scale(-1);
    left: 25% !important;
}

.react-datepicker__navigation--next {
    left: 7% !important;
    right: unset !important;
}

.svg-no-fill svg {
    stroke: var(--c-main-700);
    fill: transparent !important;
}

.svg-no-fill path {
    fill: transparent !important;
}

.btn-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-block: 3vmin;
    gap: 2vmin;
    max-width: 95%;
    margin-inline: auto;
}

.check-input-group {
    display: flex;
    column-gap: 2vmin;
    flex-wrap: wrap;
    position: relative;
    max-width: fit-content;
    align-items: end;
}

.check-add-btn {
    top: unset !important;
    transform: unset !important;
    left: unset !important;
    margin-top: 15px;
    aspect-ratio: unset !important;
    width: unset !important;
    padding-inline: 15px;
}

.checks-rows {
    position: relative;
}

.checks-title {
    border-bottom: unset !important;
    margin: unset !important;
    font-size: 1.4em !important;
}

.checks-remove {
    transform: unset !important;
}

.table-container {
    overflow: auto;
    padding-inline: 1vmin;
    /* display: flex; */
    /* align-items: baseline; */
}

.table-container.grid-coms {
    display: grid;
    grid-template-columns: 40px 1fr;
    padding-bottom: 5vmin;
    gap: 1vmin;
}

.table-container::-webkit-scrollbar {
    height: 1vmin;
}

.sub-date-notif {
    width: 100%;
    font-size: 1.2em;
    /* background-color: var(--c-error); */

    font-weight: 700;
    padding: 0.2em;
}

.sub-date-notif.error {
    color: var(--c-error);
}

.sub-date-notif.success {
    color: var(--c-success);
}

iframe {
    width: 100%;
    border: unset;
    height: 65vh;
}

.history-container {
    z-index: 1;
    position: fixed;
    max-width: 55vw;
    max-height: 50vh;
    inset: 0;
    margin: auto;
}

.history-title {
    text-align: center;
    padding-top: 20px;
    font-weight: 700;
    font-size: 1.5rem;
    color: var(--c-main-700);
}

.exit-history {
    position: absolute;
    left: 3%;
    top: 5%;
    z-index: 1;
    cursor: pointer;
}

.history-container button {
    display: none;
}

.select-list-agents {
    position: absolute;
    z-index: 11;
    background: var(--c-main-100);
    width: 100%;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    padding: 0.7vmin;
    border-top: 1px solid var(--c-main-400);
}

.select-list-agents .inner-container {
    max-height: 150px;
    padding: 1vmin;
    overflow: auto;
}

.select-list-agents .inner-container .listItem {
    font-size: 0.85em;
    display: flex;
    gap: 0.5em;
    border-bottom: 1px solid var(--c-main-400);
    margin-inline-start: -1vmin;
    cursor: pointer;
}

.select-list-agents .inner-container .listItem:hover,
.select-list-agents .inner-container .listItem.active {
    color: var(--c-main-600);
}

.select-list-agents .option {
    max-width: fit-content;
}

.select-list-agents .option:hover {
    text-decoration: underline;
    cursor: pointer;
}

.container-bank {
    display: flex;
    gap: 2vmin;
    margin-block: 2vmin;

}

.credit-card-payment .title {
    font-size: var(--fz-accent);
    font-weight: 500;
    color: var(--c-main-700);
    text-align: center;
    padding-block: 0.1em;
    border-block: 1px solid var(--c-active-nav-item);

}

.credit-card-payment select {
    border-radius: var(--border-radius);
    border: 1px solid var(--c-main-100);
    background-color: var(--c-main-100);
    padding: 0.3vmin 1vmin;
    flex-grow: 1;
}

.credit-card-payment select:focus {
    border: 1px solid var(--c-main-500);
    outline: none;
}

.credit-card-payment .small-input:has(select) {
    max-width: 15ch !important;
    flex-grow: 1;
}

.checks-modal {
    position: absolute;
    background-color: var(--c-neutral-100);
    min-height: 50%;
    min-width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: var(--box-shadow-150);
    border-radius: var(--border-radius);
    padding: 2vmin;
}

.checks-modal .add-btn {
    margin-top: 3vmin;
    margin-inline: auto 0;
    width: max-content;
}

.checks-table {
    border-spacing: 0;
    font-size: 1.12em;
}

.checks-table .check-heading {
    background: var(--c-main-500);
}

.checks-table .check-heading th {
    font-weight: 500;
}

.checks-table .check-heading th,
.checks-table tr td {
    padding-inline: 1vmin;
}

.checks-table tr:not(:first-of-type) td {
    border-bottom: 1px solid var(--c-main-500);
    font-weight: 300;
}

.loader-screen {
    position: fixed;
    inset: 0;
    background-color: var(--c-neutral-100);
    z-index: 111;
}

.loader-screen::after,
.loader-screen::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    width: 100%;
    background-color: var(--c-main-400);
}

.loader-screen::before {
    opacity: 0.5;
}

.loader-screen::after {
    transform: translateX(-100%);
    animation: loaderBar 0.7s infinite cubic-bezier(0.1, 0.55, 1, 0.43);
}

.agent-select {
    display: flex;
    align-items: center;
    gap: 3vmin;

}

.agent-select span {
    font-size: 1.2em;
    font-weight: 500;
}

.agent-select .css-b62m3t-container {
    width: 285px;
}

.css-1nmdiq5-menu {
    max-height: 300px;
    overflow-y: auto;

}



.agent-select .css-13cymwt-control {
    background: transparent;
    border-color: var(--c-main-700);
    border-radius: 23px;
    padding-block: 0.25em;
}

@media print {
    .buttons {
        display: none !important;
    }
}

@keyframes loaderBar {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.8);
    }

    10% {
        transform: scale(1.3);
    }

    25% {
        transform: scale(0.95);
    }

    50% {
        transform: scale(1.05);
    }

    75% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(1.05);
    }
}

@keyframes loading {
    0% {
        opacity: 0.2;
    }

    20% {
        opacity: 0.8;
    }

    0% {
        opacity: 0.2;
    }

    20% {
        opacity: 0.8;
    }

    0% {
        opacity: 0.4;
    }

    20% {
        opacity: 0.9;
    }

    0% {
        opacity: 0.6;
    }

    100% {
        opacity: 0.2;
    }
}

/* @keyframes loading {
    from {
        left: -100%;
    }

    to {
        left: 120%;
    }
} */

/* width */
::-webkit-scrollbar {
    width: 1vmin;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--c-main-500);
    border-radius: 100vw;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--c-main-600);
}